.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  //background-color: #fafafa;
  background-color: #ffffff;
  //border: 1px dashed #e9e9e9;
  border-radius: 5px;
}
.steps-action {
  margin-top: 20px;
  text-align: center;
}
.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

.ant-upload-list-picture .ant-upload-list-item {
  height: 50px;
}

.ant-form-inline .ant-form-item {
  margin-right: 0 !important;
}